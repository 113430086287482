import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Layout } from "../components/layout"
import { get } from "lodash"
import Helmet from "react-helmet"
import Star from "../images/svg/star.svg"
import StarsMultiple from "../images/svg/stars-multiple.svg"

export default function FeaturedLinks() {
  // const campaignLink = {
  //   title: "Some sort of Campaign",
  //   url: "https://averygoodplan.com",
  //   target: "_blank"
  // }

  const campaignLink = null

  /* Query for all featured links. Access query results with `data` in component. */
  const data = useStaticQuery(graphql`
    query MyQuery {
      links: prismicFeaturedLinks {
        data {
          links {
            active
            short_description {
              richText
            }
            title {
              richText
              html
            }
            url {
              url
              slug
              raw
              link_type
              target
            }
            alt_text {
              richText
            }
          }
        }
      }
      latestPost: allPrismicPosts(
        limit: 1
        sort: { order: DESC, fields: data___date }
      ) {
        nodes {
          data {
            title {
              richText
            }
            date: date(formatString: "YYYY-MM-DD")
          }
          uid
        }
      }
    }
  `)

  /* Destructure links and title variables from data object */
  const links = get(data, "links.data.links", null)
  const post = get(data, "latestPost.nodes[0]", null)
  return (
    <Layout>
      <Helmet>
        <body className="links-page" />
      </Helmet>
      {post ? (
        <section id="latest-post">
          <h2>
            <Star className="star" /> Latest Blog Post <Star className="star" />
          </h2>
          <Link to={`/blog/${post.data.date}/${post.uid}`} className="link">
            {post.data.title.richText[0].text}
          </Link>
        </section>
      ) : (
        ""
      )}

      <section id="featured-links">
        <h1>
          <Star className="star" /> Featured Links <Star className="star" />
        </h1>
        {campaignLink ? (
          <div className="campaign-wrapper">
            <div className="ribbon ribbon-top-left">
              <span>
                <StarsMultiple />
              </span>
            </div>
            <Link
              className="link"
              to={campaignLink.url}
              target={campaignLink.target}
            >
              {campaignLink.title}
            </Link>
          </div>
        ) : (
          ""
        )}

        <Link
          className="link"
          to={`/shop/print-books/3-month-daily-planner-thought-workbook/`}
        >
          Buy the 3-Month Daily Planner & Thought Workbook
        </Link>
        <Link
          className="link"
          to={`/shop/accessories/planner-sleeve-kaylas-epic-quest/`}
        >
          Buy a Planner Sleeve
        </Link>
        {links
          ? links.map((link, index) => (
              <a
                className="link"
                href={link.url.url}
                alt={link.title.richText[0].text}
                title={link.title.richText[0].text}
                key={index}
                target={
                  link.url.raw.target == null ? "_self" : link.url.raw.target
                }
              >
                {link.title.richText[0].text}
              </a>
            ))
          : ""}
      </section>

      <section id="other-adventures">
        <h2>
          <Star className="star" /> Our Other Adventures
          <Star className="star" />
        </h2>
        <a
          href="https://brightersidestudio.com"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          Brighter Side Studio - Contract Work
        </a>
        <a
          href="https://wilderandweathered.com"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          Wilder & Weathered - Creative Living
        </a>
      </section>
    </Layout>
  )
}
